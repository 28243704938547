import { Component, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth-service';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss'],
})
export class BurgerMenuComponent {
  constructor(private _authService: AuthService) {}
  safire_role: string | undefined;
  @ViewChild('drawer')
  public drawer?: MatDrawer;

  ngOnInit() {
    // let role = this._authService.getUserDetails()
    // this.safire_role = role.payload['custom:safire_role']?.toString()
  }

  onMenuClicked(value: any) {
    this.drawer?.toggle();
  }

  closeDrawer() {
    this.drawer?.close();
  }
}
