export const environment = {
  production: false,
  useDevToken: false,
  devToken: '',
  name: 'dev',
  version: '1.0.0',
  region: 'us-gov-west-1',
  url: 'https://dev-survey.saffire.org',
  endpoint: 'https://g778moipk6.execute-api.us-gov-west-1.amazonaws.com/dev',
  sandboxEnabled: false,
  refreshEndpoint: 'https://dev-be.cristlgov.com/auth/refreshToken',
  uamUrl: 'https://dev.cristlgov.com',
  cookieInfo: {
    domain: '.cristlgov.com',
  },
  amplify: {
    Auth: {
      identityPoolId: 'us-gov-west-1:045de7ba-c8f7-4f75-bcf7-8c11ac85d2cb',
      region: 'us-gov-west-1',
      userPoolId: 'us-gov-west-1_qCxXRs6hT',
      userPoolWebClientId: '1lt3dduac3qbhfru5lvl15gmjk',
    },
  },
};
