import { Component } from '@angular/core';

@Component({
  selector: 'app-sapphire',
  templateUrl: './sapphire.component.html',
  styleUrls: ['./sapphire.component.scss']
})
export class SapphireComponent {

}
