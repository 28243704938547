import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'textarea[appAutoResize]',
})
export class AutoResizeDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('input') onInput(): void {
    this.resize();
  }

  ngOnInit(): void {
    this.resize();
  }

  private resize(): void {
    const textarea = this.elementRef.nativeElement;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }
}
