import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth-service';
interface userDisplay {
  name: string;
  lastName: string;
}
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  userDetail: userDisplay = {
    name: '',
    lastName: '',
  };
  constructor(private _router: Router, private _authService: AuthService) {}
  @Output() onMenuClicked = new EventEmitter<any>();

  ngOnInit() {
    this.getUserDetails();
  }

  menuClick() {
    this.onMenuClicked.emit('menuClicked');
  }

  goHome() {
    this._router.navigate(['/']);
  }

  getUserDetails() {
    let det = this._authService.getUserDetails();
    console.log(
      "det.payload['family_name']?.toString()",
      det.payload['family_name']?.toString()
    );
    console.log(
      "det.payload['name']?.toString()",
      det.payload['name']?.toString()
    );
    this.userDetail.lastName = det.payload['family_name']?.toString() as string;
    this.userDetail.name = det.payload['name']?.toString() as string;
    console.log('det', this.userDetail);
  }

  capitalize(name: string): string {
    return name && name.length > 1
      ? name.charAt(0).toUpperCase() + name.slice(1)
      : name;
  }
}
